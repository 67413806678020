<template>

  <container v-if="hasAccessToFeature">
    <slot></slot>
  </container>
  <container v-else-if="suggestedSubscription">
    <div class="locked-container alert mb-0 alert-light" role="alert" :style="'height: ' + containerHeight + '; width: ' + containerWidth">
      <h4 class="text-white">
        <i class="fa-solid fa-lock mr-2"></i>
        This feature requires <strong>{{ suggestedSubscription ? suggestedSubscription.name : suggestedSubscription }}</strong>
      </h4>
      <div>
        Click
        <a v-if="openInvoices.length === 0" class="hyperlink" @click="showChangeSubscriptionModal = true">here</a>
        <el-tooltip v-else :content="'Make sure all open invoices are paid'" placement="bottom" data-toggle="tooltip">
          <a class="hyperlink">here</a>
        </el-tooltip>
        to upgrade your plan, or contact us for a trial.
      </div>
    </div>

    <modal size="lg" :show.sync="showChangeSubscriptionModal">
      <h6 slot="header" class="modal-title">Upgrade subscription</h6>
      <SubscriptionFeatureUpgradeCards :currentSubscription="currentSubscription" :suggestedSubscription="suggestedSubscription" v-model="selectedSubscription" />
      <template slot="footer">
        <base-button type="secondary" @click="showChangeSubscriptionModal = false">Close</base-button>
        <base-button type="primary" :disabled="! suggestedSubscription" @click="updateSubscription">Upgrade</base-button>
      </template>
    </modal>
  </container>

</template>

<script>
import SubscriptionFeatureUpgradeCards from '@/components/Inputs/SubscriptionFeatureUpgradeCards'

export default {
  name: "UnlockFeature",
  props: {
    feature: String,
    height: String,
    width: String
  },
  components: {
    SubscriptionFeatureUpgradeCards
  },
  data() {
    return {
      showChangeSubscriptionModal: false,
      selectedSubscription: null
    }
  },
  computed: {
    containerHeight() {
      if (! this.height) {
        return 'auto;'
      }
      return this.height;
    },
    containerWidth() {
      if (! this.width) {
        return 'auto;'
      }
      return this.width;
    },
    isAdmin() {return this.$store.getters.isAdmin},
    allInvoices() {return this.$store.getters.accountInvoices},
    openInvoices() {
      return this.allInvoices.filter(invoice => invoice.status !== 'paid' && invoice.status !== 'void')
    },
    currentAccount() {return this.$store.getters.currentAccount},
    currentSubscription() {
      this.selectedSubscription = this.currentAccount.subscription.database_name;
      return this.currentAccount.subscription;
    },
    subscriptions() {return this.$store.getters.subscriptions},
    subscriptionOptions() {
      let allSubscriptions = [...this.subscriptions]; // clone to prevent infinite loops
      if (this.currentSubscription && !allSubscriptions.some(x => x.database_name === this.currentSubscription.database_name)) {
        allSubscriptions.push(this.currentSubscription);
      }
      return allSubscriptions;
    },
    hasAccessToFeature() {
      return this.currentAccount && this.currentSubscription &&
        (this.isAdmin || this.currentSubscription.features.includes(this.feature));
    },
    suggestedSubscription() {
      let closestSubscription = null;
      if (! this.currentAccount || ! this.subscriptions || ! this.currentSubscription || this.hasAccessToFeature) {
        return null;
      }
      let allSubscriptions = [...this.subscriptions]; // clone to prevent infinite loops
      allSubscriptions.sort(this.compareSubscriptions).forEach((subscription) => {
        if (closestSubscription === null && subscription.features.includes(this.feature)) {
          closestSubscription = subscription;
        }
      });
      // if (closestSubscription) {
      //   this.selectedSubscription = closestSubscription.database_name;
      // }
      return closestSubscription;
    }
  },
  methods: {
    updateSubscription() {
      let self = this;
      if (! this.suggestedSubscription) {
        return;
      }
      this.$store.dispatch('updateBilling', this.suggestedSubscription)
        .then(function(res) {
          self.showChangeSubscriptionModal = false;
        })
        .catch(function(err) {
          self.$swal.fire({
            icon: 'error',
            title: 'Oops....',
            text: err.response?.data.message || err,
            customClass: {
              confirmButton: 'btn btn-primary btn-fill'
            }
          })
        })
    },
  },
  mounted() {
    if (! this.subscriptions.length) {
      this.$store.dispatch('getSubscriptions')
    }
  }
}
</script>

<style scoped>
a.hyperlink{
  color: #d64f28 !important;
  text-decoration: none;
  cursor: pointer;
}
a.hyperlink:hover{
  text-decoration: underline;
}
</style>
