<template>
  <modal v-if="user" :show="show" v-on:update:show="onClose">
    <template slot="header">
      <h5 class="modal-title">Editing Profile</h5>
    </template>
    <div class="mb--4">
      <form @keyup.enter.prevent="onsubmit" @submit.prevent="onsubmit">
        <div>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                type="text"
                name="name"
                label="First Name"
                v-model="user.first_name"
              >
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input
                type="text"
                name="name"
                label="Last Name"
                v-model="user.last_name"
              >
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input
                type="text"
                name="phonenumber"
                label="Phone Number"
                v-model="user.phone_number"
              >
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input
                type="text"
                name="email"
                disabled
                label="Email"
                v-model.trim="user.email"
              >
              </base-input>
            </div>
          </div>
        </div>
      </form>
    </div>
    <template slot="footer">
      <base-button type="secondary" @click="onClose">{{changesMade ? 'Cancel' : 'Close' }}</base-button>
      <base-button type="primary" @click="onSubmit">Save changes</base-button>
    </template>
  </modal>
</template>

<script>

export default {
  name: "EditUserInformationModal",
  props: ["show"],
  mounted() {
    let self = this;

    this.watchUserChange = false;
    this.$nextTick(function () {
      this.watchUserChange = true;
    })
    this.user = this.$store.state.auth.user;
  },
  watch: {
    user: {
      handler(newVal){
        if(this.watchUserChange){
          this.changesMade = true;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      watchUserChange: true,
      changesMade: false,
      user: undefined,
    };
  },
  methods: {
    onSubmit() {
      let self = this;

    this.$store.dispatch('updateUserInformation', this.user)
        .then(function(){
        self.onClose()
        })
        .catch(function(err) {
        alert(err)
        })
    },
    onClose() {
      this.changesMade = false;
      this.user = JSON.parse(JSON.stringify(this.$store.state.auth.user));
      this.$emit('modalClose');
    },
  },
}
</script>
