<template>
  <div class="wrapper">
    <invoices-warning ref="invoicesWarning"></invoices-warning>
    <side-bar :style="hasWarning() ? ('transition: none; margin-top: ' + invoicesWarningHeight + 'px') : ''">
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-shop top-1px text-default',
            path: '/dashboard'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee'"
          :link="{
            name: 'Analytics',
            icon: 'ni ni-chart-pie-35 top-2px text-default',
            path: '/analytics',
          }">
        </sidebar-item>
        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee'"
          :link="{
            name: 'Orders',
            icon: 'ni ni-bullet-list-67 top-1px text-default',
            path: '/orders?page=1&results=10',
          }">
        </sidebar-item>
        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee'"
          :link="{
            name: 'Payments',
            icon: 'ni ni-credit-card top-1px text-default',
            path: '/payments?page=1&results=10',
          }">
        </sidebar-item>
        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee'"
          :link="{
            name: 'Menu',
            icon: 'ni ni-collection top-1px text-default',
          }">

          <sidebar-item :link="{ name: 'Menu editor', path: '/menu/menus' }"/>
          <sidebar-item :link="{ name: 'Cards', path: '/menu/cards' }"/>
          <sidebar-item :link="{ name: 'Categories', path: '/menu/categories' }"/>
          <sidebar-item :link="{ name: 'Products', path: '/menu/items' }"/>
          <sidebar-item :link="{ name: 'Option Groups', path: '/menu/modifiers'}"/>
          <sidebar-item :link="{ name: 'Recommendations', path: '/menu/recommendations'}"/>
        </sidebar-item>

        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee' && currentAccount && currentAccount.subscription !== null &&
                      currentAccount.subscription && currentAccount.subscription.features && currentAccount.subscription.features.includes('inventory_management')"
          :link="{
            name: 'Inventory',
            icon: 'fa fa-shopping-cart text-default',
            path: '/inventory',
          }">
        </sidebar-item>

        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee'"
          :link="{
            name: 'QR codes',
            icon: 'fa fa-layer-group text-default',
            path: '/qrs',
          }">
        </sidebar-item>

        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee'"
          :link="{
              name: 'Devices',
              icon: 'ni ni-tablet-button top-2px text-default',
              path: '/devices',
          }">
        </sidebar-item>
        <span v-if="billingStatus === false && canAccessBilling" class="redDot"></span>
        <sidebar-item
          v-if="$store.getters.currentRole !== 'employee'"
          :link="{
            name: 'Settings',
            icon: 'ni ni-settings-gear-65 top-2px text-default',
            path: '/settings',
          }">
        </sidebar-item>

        <sidebar-item
          v-if="$store.getters.currentRole === 'employee'"
          :link="{
              name: 'My tips',
              icon: 'fa fa-sack-dollar text-default',
              path: '/tips',
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Help',
            icon: 'ni ni-support-16 top-2px text-default',
            path: '/support',
          }">
        </sidebar-item>

        <sidebar-item
          v-if="$store.getters.canManageAccounts"
          :link="{
            name: 'Partner portal',
            icon: 'fa fa-user-shield text-default',
            path: '/reseller',
          }">
        </sidebar-item>
        <sidebar-item
          v-if="$store.getters.isAdmin"
          :link="{
            name: 'Admin',
            icon: 'fa fa-user-shield text-default',
            path: '/admin',
          }">
        </sidebar-item>
        <sidebar-item
          v-if="$store.getters.isAdmin"
          :link="{
            name: 'Activity Log',
            icon: 'fas fa-clipboard-list text-default',
            path: '/activity-log',
          }">
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content" :style="hasWarning() ? ('transition: none; margin-top: ' + invoicesWarningHeight + 'px') : ''">
      <dashboard-navbar></dashboard-navbar>
      <data-preloader></data-preloader>

      <notifications></notifications>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './Navbar.vue';
import ContentFooter from './Footer.vue';
import DataPreloader from "@/views/Components/DataPreloader";
import InvoicesWarning from "@/views/Components/InvoicesWarning";
import { FadeTransition } from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DataPreloader,
    InvoicesWarning,
    FadeTransition,
  },
  computed: {
    billingStatus() {return this.$store.state.auth.onboarding.billingStatus},
    invoicesWarningHeight() {return this.$refs.invoicesWarning.height},
    currentAccount() {return this.$store.getters.currentAccount},
    canAccessBilling() {return ! this.currentAccount.is_whitelabel},
  },
  methods: {
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('scrollbar-inner');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    hasWarning() {
      return this.$refs.invoicesWarning && this.$refs.invoicesWarning.overdueInvoices.length > 0;
    }
  }
};
</script>

<style scoped>
  .redDot{
    position: relative;
    margin: -4px;
    padding: 4px;
    margin-left: 220px;
    border-radius: 50%;
    background: red;
    color: white;
    top: 18px;
    left: -183px;
  }
</style>
