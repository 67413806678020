import clickOutside from '@/directives/click-ouside.js';
import resize from '@/directives/resize.js';
import scroll from '@/directives/scroll.js';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.directive('resize', resize);
    Vue.directive('scroll', scroll);
  }
};

export default GlobalDirectives;
