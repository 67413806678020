<template>
  <div>
    <div v-if="showFilter" class="mx-4 mb-4">
      <base-button @click="applyFilter">Apply</base-button>
      <base-button @click="resetFilter">Reset</base-button>
    </div>

    <el-table
      v-if="!menuIsLoading"
      class="table-responsive table-flush cursor-context-menu sticky-header"
      header-row-class-name="thead-light"
      :data="recommendations"
      @selection-change="handleSelectionChange">

      <el-table-column
        type="selection"
        width="55">
      </el-table-column>

      <el-table-column
        :label="'Product'"
        min-width="240px"
        prop="name"
        sortable>
        <template v-slot="{row}">
          <div class="media align-items-center">
            <a href="#" class="avatar rounded-circle mr-3">
              <img v-if="row.item_image" :alt="row.item_image"
                   :src="'https://orderli.com/cdn-cgi/image/width=100,quality=60/https://imagedelivery.net/xS_5nksgKmcoB2_mcBGUmA/' + row.item_image"
                   @error="placeholderImgUrl">
            </a>
            <div>
              <h5 class="font-weight-600 name mb-0 text-sm">{{row.item_name}}</h5>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="'Recommendations'"
        min-width="240px"
        prop="name"
        sortable>
        <template v-slot="{row}">
          <div class="mb-n4 py-2">

            <div class="media align-items-center mb-4"
                 v-for="recommendation in row.recommendations" :key="recommendation.item_id">
              <a href="#" class="avatar rounded-circle mr-3">
                <img v-if="recommendation.item_image" :alt="recommendation.item_image"
                     :src="'https://orderli.com/cdn-cgi/image/width=100,quality=60/https://imagedelivery.net/xS_5nksgKmcoB2_mcBGUmA/' + recommendation.item_image"
                     @error="placeholderImgUrl">
              </a>
              <div>
                <h5 class="font-weight-600 name mb-0 text-sm">{{recommendation.item_name}}</h5>
                <h5 class="font-weight-600 combination-count mb-0 text-sm">{{recommendation.combination_count}}x combined ({{recommendation.combination_percentage}}% of orders)</h5>
              </div>
            </div>

          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="'Active'"
        min-width="240px"
        prop="name"
        sortable>
        <template v-slot="{row}">
          <div class="mb-n4 py-2">

            <div class="mb-4" v-for="recommendation in row.recommendations" :key="recommendation.item_id">
              <div @click.stop style="height: 48px; padding-top: 12px">
                <base-switch on-text="On" off-text="Off" v-model="recommendation.active"
                             v-on:input="toggleActive($event, row.item_id, recommendation)"></base-switch>
              </div>
            </div>

          </div>
        </template>
      </el-table-column>
    </el-table>

    <div v-else class="d-flex justify-content-center py-8">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <button v-if="recommendations.length >= 2" class="btn btn-primary mt-3" @click="$router.push('/menu/recommendations')">Show more</button>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  import {Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui';

  export default {
    name: 'RecommendationsTable',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    props: ["showFilter", "showCompact", "inactiveProducts"],
    data() {
      return {
        multipleSelection: [],
        filterFunction: "item => true",
        appliedFilterFunction: (item => true),
      };
    },
    created() {
      this.$store.dispatch('getMenuIfUndefined');
    },
    computed: {
      ...mapGetters({
        optionGroupsMap: 'optionGroupsMap',
        menuIsLoading: 'menuIsLoading',
        inactiveItemsIsLoading: 'inactiveItemsIsLoading',
        currentRestaurant: 'currentRestaurant'
      }),
      recommendations() {
        if (this.showCompact) {
          return this.$store.getters[`${'recommendationsOfCurrentRestaurant'}`].filter(this.appliedFilterFunction).slice(0, 2);
        }
        return this.$store.getters[`${'recommendationsOfCurrentRestaurant'}`].filter(this.appliedFilterFunction);
      },
      noRecommendationsAccess() {
        return this.$store.getters[`${'recommendationsNotSupportedForSubscription'}`];
      }
    },
    methods: {
      toggleActive(event, item_id, recommendation) {
        if (recommendation.active) {
          let payload = {
            restaurant_id: this.currentRestaurant.id,
            item_id: item_id,
            recommendation_id: recommendation.item_id,
          }
          this.$store.dispatch('newRecommendation', payload)
            .then(function () {
              console.log('recommendation added');
            })
            .catch(function (err) {
              alert(err)
            })
        } else {
          let payload = {
            item_id: item_id,
            recommendation_id: recommendation.item_id,
          }
          this.$store.dispatch('deleteRecommendation', payload)
            .then(function () {
              console.log('recommendation deleted');
            })
            .catch(function (err) {
              alert(err)
            })
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        this.$emit('multipleSelect', val);
      },
      placeholderImgUrl(event) {
        let placeholderSrc = "/img/menu/image-placeholder-350x350.png";//http://www.stleos.uq.edu.au/wp-content/uploads/2016/08/image-placeholder-350x350.png";
        if (event.srcElement.currentSrc == placeholderSrc) {
          console.log("we got into a loop");
        } else {
          event.target.src = placeholderSrc;
        }
      },
      applyFilter(){
        this.appliedFilterFunction = eval(this.filterFunction);
      },
      resetFilter(){
        this.filterFunction = "item => true";
        this.appliedFilterFunction = (item => true);
      }
    },
  }
</script>
