<template>
    <tippy  v-bind="$attrs"
            :arrow="arrow"
            :arrowType="arrowType"
            :animation="animation"
            :theme="theme"
            :maxWidth="maxWidth"
            class="d-inline-block"
    >
        <template v-slot:trigger>
          <slot name="customIcon">
            <i  :class="icon"
                style="padding: 4px; margin: -5px 0; font-size: 80%"
                :style="{color: color}"></i>
          </slot>
        </template>
        <slot class="custom-class"></slot>
    </tippy>
</template>

<script>
import "tippy.js/themes/light.css";
// import "tippy.js/themes/light-border.css";

export default {
    name: "InfoIcon",
    props: {
        arrow: {
            type: Boolean,
            default: true,
            description: 'Determines if an arrow should be added to the tippy pointing toward the reference element'
        },
        arrowType: {
            type: String,
            default: 'round',
            description: 'Determines the arrow type. Possible values: sharp, round'
        },
        animation: {
            type: String,
            default: 'scale',
            description: 'The type of transition animation. Possible values: shift-away, shift-toward, fade, scale, perspective'
        },
        theme: {
            type: String,
            default: 'light',
            description: 'Themes added as classes (each separated by a space) to the tippy elements classList'
        },
        icon: {
          type: String,
          default: 'fas fa-info-circle',
          description: 'Add a custom icon class, default is \'fas fa-info-circle\', or use \'fas fa-eye\', \'fas fa-question-circle\', \'fas fa-exclamation-triangle\', \'fas fa-clock\' '
        },
        // clock: Boolean,
        // info: Boolean,
        // preview: Boolean,
        // question: Boolean,
        // warning: Boolean,
        maxWidth: {
          type: Number,
            default: 450,
        },
        color: {
            type: String,
            default:  '#b8bbd2',
        }
    }
}
</script>

<style>
  .tippy-content{
    padding: 8px;
    text-align: left;
  }

  .no-padding-theme .tippy-content{
    padding: 0;
  }
</style>

<style scoped>
  .custom-class /deep/ .tippy-content{
    padding: 8px;
    text-align: left;
  }
</style>
