<template>
    <div @click="$router.push(cardLink)" class="dropdown-header m-0 link">
        <div class="" style="margin-left: 0.2rem; display: flex; align-items: center;">
            <div class="circle-background">
                <img class="quickSelectIcon quickselect-icon"><i class="fas fa-exclamation" style="color: #b53a17"></i>
            </div>
            <h6 class="dropdown-header m-0 card-title">{{ cardTitle }}
                <span>
                    <h6 class="m-0 notification-description">{{cardDescription}}</h6>
                </span>
            </h6>
            <div>
                <img class="quickSelectIcon quickselect-icon"><i class="fas fa-chevron-right chevron"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationCard",
    props: ['cardTitle', 'cardDescription', 'cardLink', 'cardIcon'],
    components: {
    },
    computed: {
      mandateGiven() {
        return this.$store.getters.mandateGiven;
      },
      canManageAccounts() {
        return this.$store.getters.canManageAccounts;
      }
    }
}
</script>

<style>
 .card-title {
    box-sizing: border-box;
    width: 100%;
    padding-left: 0.6rem;
    font-size: .65rem;
    color: #32325d;
 }

 .circle-background {
    height: 30px;
    background: #f0c0b2;
    border-radius: 50%;
    -webkit-box-shadow: 1px 1px 5px hsl(0deg 0% 69% / 23%);
    box-shadow: 1px 1px 5px hsl(0deg 0% 69% / 23%);
    min-width: 30px;
    border: none;
    margin-left: -3px;
    margin-right: 8px;
    padding: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
 }

 .link:hover {
     background-color: #f6f9fc /*#fbfaf8; Orderli warm white; */;
 }

 .notification-description {
     text-transform: none;
     color: #525f7f;
 }

 .chevron {
    font-size: 15px;
    padding-right: 5px;
    padding-left: 15px;
    color: white;
 }

 .link:hover .chevron {
    color: #525f7f;
 }
</style>