<template>
  <div class="btn-group-toggle" data-toggle="buttons">
    <label v-for="(subscription, index) in subscriptionOptions"
           :key="index"
           class="btn"
           :class="[{
             active: (value === subscription.database_name),
             suggested: (suggestedSubscription && subscription.database_name === suggestedSubscription.database_name)
             }, buttonClasses
           ]">
      <span v-if="index === 0">Current Plan</span>
      <span v-if="index === 1">New Plan</span>
      <input :value="subscription.database_name" v-model="model" disabled type="radio" autocomplete="off" checked="">
      <div class="content pb-3">
        <h2 class="font-weight-900 pt-2">{{subscription.name}}</h2>
        <span class="font-weight-900" style="font-size: 1.6rem">€{{subscription.price}},-</span> <span> / month</span><br>
        <span v-if="subscription.features.includes('multiple_locations')">€{{subscription.extra_location_price}},- / month for additional location</span>
        <span v-else>No extra locations</span>
        <ul class="pt-2">
          <li v-for="feature in subscription.features" style="text-align: left">
            {{featuresDict[feature] || feature}}
          </li>
        </ul>
        <div class="mt-auto mb-0">
          <h4>PAYMENTS</h4>
          <span v-for="method in subscription.methods" class="status badge badge-dot mr-2 my-1" style="background: #f6f9fc; color: #525f7f; padding: 6px; font-size: 0.75rem; border-radius: 10px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);">
            {{method.method + (method.issuer ? ` [${method.issuer}]` : '') + ' (' + (method.price ? '€' + method.price : '') + (method.price && method.percentage ? ' + ' : '') + (method.percentage ? method.percentage + '%' : '') + ')'}}
          </span>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
  export default {
    name: "SubscriptionCards",
    data() {
      return {
        featuresDict: {
          multiple_locations: "Multiple Locations",
          unlimited_tablets: "Unlimited Tablets",
          inventory_management: "Inventory Management",
          pos_integration: "POS Integration",
          receipt_printer: "Receipt Printer",
          up_selling: "Upselling",
          cross_selling: "Cross-selling",
        }
      }
    },
    props: {
      value: {
        type: [String, Number],
        description: 'Radio value'
      },
      buttonClasses: {
        type: [String, Object],
        description: 'Inner button css classes'
      },
      currentSubscription: {
        type: Object,
        description: 'Subscription Object'
      },
      suggestedSubscription: {
        type: Object,
        description: 'Subscription Object'
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    computed: {
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('change', val)
        }
      },
      subscriptions() {
        return this.$store.getters.subscriptions;
      },
      subscriptionOptions() {
        let allSubscriptions = [];
        if (this.currentSubscription) {
          allSubscriptions.push(this.currentSubscription);
        }
        if (this.suggestedSubscription) {
          allSubscriptions.push(this.suggestedSubscription);
        }
        return allSubscriptions;
      },
    },
    mounted() {
      if (!this.subscriptions.length){
        this.$store.dispatch('getSubscriptions')
      }
    }
  }
</script>

<style scoped>
  label > span {
    font-size: 0.8rem;
  }
  .btn-group-toggle {
    display: flex;
    margin: 0 -1rem;
  }
  .btn-group-toggle .btn{
    margin: 0 1rem !important;
    flex: 1 1 0px;
    /*padding: 0;*/
    cursor: initial !important;
  }
  .btn-group-toggle .btn .content{
    /*margin: 0.625rem 1.25rem;*/
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .btn.active h2, .btn.active h4{
    color: white;
  }
  .btn.active .badge {

  }

  .suggested {
    border: 3px dashed #d64f28 !important;
  }

  /*.btn.active{*/
  /*  border: solid red 10px;*/
  /*}*/
</style>
