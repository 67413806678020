<template>
  <container :class="containerClass" v-if="contentIsVisible">
    <slot></slot>
  </container>
</template>

<script>
export default {
  name: "Only",
  props: {
    admins: Boolean,
    nonAdmins: Boolean,
    if: {
      type: [String, Boolean],
      default: undefined
    },
    role: String
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    currentRole() {
      return this.$store.getters.currentRole;
    },
    containerClass() {
      if (this.admins) {
        return 'admins';
      }
      if (this.nonAdmins) {
        return 'non-admins';
      }
      if (this.role) {
        return 'role-' + this.role;
      }
      return '';
    },
    contentIsVisible () {
      if (this.if !== undefined) {
        if (typeof this.if === "string") {
          if (! this.$store.getters[this.if]) {
            return false;
          }
        } else {
          if (! this.if) {
            return false;
          }
        }
      }

      if (this.admins) {
        return this.isAdmin;
      }
      if (this.nonAdmins) {
        return ! this.isAdmin;
      }
      if (this.role) {
        return this.role === this.currentRole;
      }

      return this.if !== undefined;
    }
  }
}
</script>

<style lang="scss">
  .admins {
  }
  .non-admins {
  }

  .role-admin {
  }
  .role-owner {
  }
  .role-manager {
  }
  .role-employee {
  }
</style>
