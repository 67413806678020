import {restaurantCurrency, restaurantCurrencyCompact} from "@/filters/restaurantCurrency";
import {
  dateString,
  localDateString,
  localDateTimeString,
  dateTimeString,
  daysBetween,
  addDays,
} from "@/filters/dateTime";
import swal from "sweetalert2";

/**
 * You can register global filters here and use them as a plugin in your main Vue instance
 */

const GlobalFilters = {
  install(Vue) {
    Vue.filter('restaurantCurrency', restaurantCurrency);
    Vue.prototype.restaurantCurrency = restaurantCurrency;
    Vue.filter('restaurantCurrencyCompact', restaurantCurrencyCompact);
    Vue.prototype.restaurantCurrencyCompact = restaurantCurrencyCompact;

    Vue.filter('date', dateString);
    Vue.prototype.dateString = dateString;
    Vue.filter('localDate', localDateString);
    Vue.prototype.localDateString = localDateString;
    Vue.filter('localDateTime', localDateTimeString);
    Vue.prototype.localDateTimeString = localDateTimeString;
    Vue.filter('dateTime', dateTimeString);
    Vue.prototype.dateTimeString = dateTimeString;
    Vue.prototype.daysBetween = daysBetween;
    Vue.prototype.addDays = addDays;
    Vue.prototype.$swal = swal;
  }
};

export default GlobalFilters;

