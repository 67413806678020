import Vue from "vue";
import store from '@/store/index';

let currencyFilterConfig = {
  USD: {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  },
  EUR: {
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  },
  MXN: {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 0,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  },
  GBP: {
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  },
  EGP: {
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  }
};

export function restaurantCurrency (value) {
  let currency = store.getters.currentRestaurantCurrency;
  let config = currencyFilterConfig[currency] || currencyFilterConfig['EUR'];
  return Vue.filter('currency')(value, config);
}

export function restaurantCurrencyCompact (value) {
  let currency = store.getters.currentRestaurantCurrency;
  let config = Object.assign({}, currencyFilterConfig[currency] || currencyFilterConfig['EUR']);
  config.fractionCount = 0;
  return Vue.filter('currency')(value, config);
}
