<template>
  <label :class="['custom-toggle', switchClass]">
    <input type="checkbox" :checked="value" :disabled="disabled" @change="handleChange">
    <span class="custom-toggle-slider rounded-circle"
          :data-label-off="offText"
          :data-label-on="onText">
    </span>
  </label>
</template>
<script>
  export default {
    name: 'base-switch',
    props: {
      value: [Array, Boolean],
      type: String,
      disabled: Boolean,
      onText: {
        type: String,
        default: 'Yes'
      },
      offText: {
        type: String,
        default: 'No'
      },
      /**
       * Determines when to show a confirmation dialog before changing the switch state.
       * - 'always': Show confirmation for both on and off changes.
       * - 'on': Show confirmation only when switching to on.
       * - 'off': Show confirmation only when switching to off.
       * - 'none': Never show confirmation (default).
       */
      confirmMode: {
        type: String,
        default: 'none',
        validator: value => ['always', 'on', 'off', 'none'].includes(value)
      },
      /**
       * The title of the confirmation dialog.
       * This will be displayed at the top of the dialog when shown.
       */
      confirmTitle: {
        type: String,
        default: 'Confirm'
      },
      /**
       * The message body of the confirmation dialog.
       * This will be displayed in the main content area of the dialog when shown.
       */
      confirmMessage: {
        type: String,
        default: 'Are you sure you want to change this setting?'
      }
    },
    computed: {
      switchClass() {
        return this.type ? `custom-toggle-${this.type}` : '';
      }
    },
    methods: {
      async confirmChange(newValue) {
        const result = await this.$swal.fire({
          title: this.confirmTitle,
          text: this.confirmMessage,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        });
        return result.isConfirmed ? newValue : this.value;
      },
      async handleChange(event) {
        const newValue = event.target.checked;
        event.target.checked = this.value; // Immediately revert the checkbox state

        if (this.confirmMode === 'always' ||
            (this.confirmMode === 'on' && newValue) ||
            (this.confirmMode === 'off' && !newValue)) {
          const confirmedValue = await this.confirmChange(newValue);
          if (confirmedValue !== this.value) {
            this.$emit('input', confirmedValue);
          }
        } else {
          this.$emit('input', newValue);
        }
      }
    }
  };
</script>
<style></style>
