<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">

      <div class="col">
        <slot>
          <h5 class="card-title text-uppercase text-muted mb-0 pl-0" v-if="title">{{title}}</h5>
          <span class="h2 font-weight-bold mb-0" v-if="! loading && subTitle">{{subTitle}}</span>

          <el-tooltip v-if="! loading && tooltip" :content="tooltip" placement="right" data-toggle="tooltip">
            <i class="ml-2 fas fa-info-circle text-muted"></i>
          </el-tooltip>

          <div v-if="loading" class="d-flex justify-content-center mt-2 mb-3">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </slot>
      </div>

      <div class="col-auto" v-if="! loading && ($slots.icon || icon)">
        <slot name="icon">
          <div class="icon icon-shape text-white rounded-circle shadow"
               :class="[`bg-${type}`, iconClasses]">
            <i :class="icon"></i>
          </div>
        </slot>
      </div>
    </div>

    <p class="mt-1 mb-0 text-sm">
      <slot name="footer" v-if="! loading">
      </slot>
    </p>
  </card>
</template>
<script>
  import Card from './Card.vue';

  export default {
    name: 'stats-card',
    components: {
      Card
    },
    props: {
      type: {
        type: String,
        default: 'primary'
      },
      icon: String,
      title: String,
      subTitle: String,
      tooltip: String,
      loading: Boolean,
      iconClasses: [String, Array]
    }
  };
</script>
<style></style>
