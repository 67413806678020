<template>
  <form :id="'setup-form-' + this.randomFormId">
    <div class="row mx--4 p-4">
      <div class="col-lg-6 order-lg-2">
        <!-- ONLY ON MOBILE-->
        <h2 class="d-lg-none mb-3">
          Add payment method
        </h2>

        <h2 class="d-none d-lg-block mb-3" v-if="showOpenInvoicesTable">
          Add payment for: <b>{{ restaurant ? restaurant.name : 'my account' }}</b>
        </h2>

        <div>
          <base-input>
            <button-radio-group :options="paymentOptions"
                                class="radio-button-group"
                                button-classes="radio-button"
                                v-model.number="paymentOption">
            </button-radio-group>
          </base-input>
        </div>

        <div class="form-group">
          <base-input
            type="text"
            label="Business name"
            label-info="Bank account holder name">
            <input name="accountholder-name" v-model="accountHolderName" class="form-control" autocomplete="name">
          </base-input>
        </div>

        <div class="form-row-stripe">
          <base-input
            type="text"
            label="Billing email address">
            <input name="email" v-model="email" class="form-control" autocomplete="email">
          </base-input>
        </div>

        <div class="form-row-stripe" v-show="paymentOption === 'ideal'">
          <base-input
            label="iDEAL Bank"
            name="ideal-bank">
            <div class="ideal-bank-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>
          </base-input>
        </div>
        <div class="form-row-stripe" v-show="paymentOption === 'card'">
          <base-input
            label="Creditcard"
            name="card-element">
            <div class="card-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>
          </base-input>
        </div>
        <div class="form-row-stripe" v-show="paymentOption === 'sepa_debit'">
          <base-input
            label="IBAN"
            name="iban-element"
          >
            <div class="iban-element">
              <!-- A Stripe Element will be inserted here. -->
            </div>
            <div class="invalid-stripe-input">
              {{ errorTextIBAN }}
            </div>
          </base-input>
        </div>

        <!--          TODO show how much the user is billed (€0,01 iDEAL, €1 Bancontact-->

        <template v-if="! hideSubmitButton">
          <button v-if="isSubmitting" class="btn btn-primary mt-4" disabled>
            <span class="spinner-border mr-1 text-white" role="status"></span>
            {{ statusText }}
          </button>
          <button v-else class="btn btn-primary mt-4">Submit</button>
        </template>

        <!-- Used to display form errors. -->
        <div id="error-message" role="alert"></div>

        <base-checkbox :checked="true" v-if="showOpenInvoicesTable" class="pt-1">
          Accept payment mandate
          <InfoIcon interactive>
            By providing your payment information and confirming this payment, you authorise (A) Orderli BV and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.
          </InfoIcon>
        </base-checkbox>
      </div>

      <div class="col-lg-6 order-lg-1">
        <div v-if="showOpenInvoicesTable" style="min-height: 150px">
          <h2 class="mt-4 mt-lg-0">
            Unpaid Invoices
          </h2>
          <div class="mt-3 pt-1">
            <InvoicesTable ref="invoicesTable" :compact="true" :only-open-invoices="true" />
          </div>
        </div>
        <template v-else>
          <h2 class="d-none d-lg-block">
            Add payment for: <b>{{ restaurant ? restaurant.name : 'my account' }}</b>
            <badge v-if="setupType === 'account'" rounded type="secondary" size="md" class="ml-2" style="font-size: 0.7rem">Required</badge>
          </h2>
          <div class="mandate-acceptance mt-3">
            <small>
              By providing your payment information and confirming this payment, you authorise (A) Orderli BV and Stripe, our payment service provider and/or PPRO, its local service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.
            </small>
          </div>
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import {loadStripe} from "@stripe/stripe-js";
import ButtonRadioGroup from "@/components/ButtonRadioGroup";
import InvoicesTable from "@/views/Settings/InvoicesTable";

export default {
  name: "PaymentMethodSetup",
  components: {
    ButtonRadioGroup,
    InvoicesTable
  },
  props: {
    restaurant: null,
    hideSubmitButton: Boolean,
    showOpenInvoicesTable: Boolean
  },
  data() {
    return {
      stripe: {}, // or whatever data type
      elements: {}, // or whatever data type
      clientSecret: null,
      setupIntent: {},
      randomFormId: Math.floor(Math.random() * 100000),
      setupType: 'account',
      paymentOption: 'ideal',
      paymentOptions:  [{value: 'ideal', label: 'iDEAL', confirmURL: 'confirmIdealSetup'}, {value: 'bancontact', label: 'Bancontact', confirmURL: 'confirmBancontactSetup'}, {value: 'card', label: 'Creditcard', confirmURL: 'confirmCardSetup'}, {value: 'sepa_debit', label: 'IBAN nr.', confirmURL: 'confirmSepaDebitSetup'}],
      accountHolderName: null,
      isSubmitting: false,
      statusText: null,
      email: null,
      idealBank: null,
      cardElement: null,
      ibanElement: null,
      errorTextIBAN: null,
    }
  },
  methods: {
    setupStripe() {
      let self = this;
      loadStripe(import.meta.env.VITE_APP_STRIPE_KEY_V2).
      then ( (stripe) =>
      {
        self.stripe = stripe;
        self.elements = stripe.elements();
        const options = {
          // Custom styling can be passed to options when creating an Element
          style: {
            base: {
              padding: '10px 12px',
              color: '#525f7f',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4'
              },
            },
          },
        };
        let formId = 'setup-form-' + self.randomFormId;

        // Create an instance of the idealBank Element
        // const idealBank = self.elements.create('idealBank', options);
        self.idealBank = self.elements.create('idealBank', options);

        // Add an instance of the idealBank Element into
        // the `ideal-bank-element` <div>
        self.idealBank.mount('#' + formId + ' .ideal-bank-element');

        self.cardElement = self.elements.create('card');
        self.cardElement.mount('#' + formId + ' .card-element');

        let ibanOptions = {
          style: options.style,
          supportedCountries: ['SEPA'],
          placeholderCountry: self.restaurant ? (self.restaurant.country || 'NL') : 'NL',
        }
        self.ibanElement = self.elements.create('iban', ibanOptions);
        self.ibanElement.mount('#' + formId + ' .iban-element');
        const countriesWithExtraInfo = ["AD", "PF", "TF", "GI", "GB", "GG", "VA", "IM", "JE", "MC", "NC", "BL", "PM", "SM", "CH", "WF"]

        self.ibanElement.addEventListener('change', (event) => {
          if (countriesWithExtraInfo.includes(event.country)) {
            // Additionally, IBANs with the country codes AD, PF, TF, GI, GB, GG, VA, IM, JE, MC, NC, BL, PM, SM, CH,
            // and WF require the country and line1 properties of the billing_details.address property.
            self.errorTextIBAN = `IBAN setup might fail, as we need a bit more info for IBANs from ${event.country}. Please contact us if setup fails.`
          }
          if (event.error) {
            self.errorTextIBAN = event.error.message;
          }
        })

        const form = document.getElementById(formId);
        form.addEventListener('submit', (event) => {
          event.preventDefault();
          self.submitForm();
        });
      })
    },
    submitForm() {
      let self = this;
      this.statusText = 'Saving';
      this.isSubmitting = true;

      if (this.restaurant) {
        // get the client secret token to confirm restaurant billing setup
        this.$store.dispatch('createRestaurantBilling', {restaurantId: this.restaurant.id, account_name: this.accountHolderName, email: this.email})
          .then(function(res) {
            self.clientSecret = res.data.secret
            self.confirmStripeSetupIntent()
          })
          .catch(function(err) {
            alert(err ? (err.message || err) : err)
            self.isSubmitting = false;
          })
      } else {
        // get the client secret token to confirm account billing setup
        this.$store.dispatch('createAccountBilling', {account_name: this.accountHolderName, email: this.email})
          .then(function(res) {
            self.clientSecret = res.data.secret
            self.confirmStripeSetupIntent();
          })
          .catch(function(err){
            alert(err ? (err.message || err) : err)
            self.isSubmitting = false;
          })
      }
    },
    confirmStripeSetupIntent() {
      let self = this;
      self.statusText = 'Redirecting';
      const billingDetails = {
        name: self.accountHolderName,
        email: self.email,
      }
      let payment_method = {
        ...(self.paymentOption === 'ideal' && {ideal: self.idealBank}),
        ...(self.paymentOption === 'card' && {card: self.cardElement}),
        ...(self.paymentOption === 'sepa_debit' && {sepa_debit: self.ibanElement}),
        billing_details: billingDetails,
      }
      let confirmMethod = self.paymentOptions.find(option => option.value === self.paymentOption).confirmURL;

      if (self.paymentOption) {
        self.stripe[confirmMethod](
            self.clientSecret,
            {
              payment_method: payment_method = {
                ...(self.paymentOption === 'ideal' && {ideal: self.idealBank}),
                ...(self.paymentOption === 'card' && {card: self.cardElement}),
                ...(self.paymentOption === 'sepa_debit' && {sepa_debit: self.ibanElement}),
                billing_details: billingDetails,
              },
              return_url: location.origin + `/settings/mandate-completion?type=${self.setupType}`,
            }
          )
          .then(result => {
            let err = result.error;
            if(!err) {
              window.location.replace(location.origin + `/settings/mandate-completion?type=${self.setupType}&setup_intent_client_secret=${result.client_secret}`);
            }
            else{
              alert(err.message || err)
            }
          })
          .catch(err => alert(err ? (err.message || err) : err))
      } else {
        alert('Error 101 - Please contact us about this error. This shouldn\'t be happening')
      }
    }
  },
  mounted() {
    if (this.restaurant) {
      this.setupType = 'restaurant';
    }
    this.setupStripe();
  },
}
</script>

<style scoped>
.mandate-acceptance{
  font-style: italic;
  /*position: absolute;*/
  /*bottom: 0;*/
}

.ideal-bank-element, .card-element, .iban-element{
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  border-collapse: separate;
  border-spacing: 0;
}
.ideal-bank-element{
  padding: 1px 0;
}
.card-element{
  padding: 12px;
}
.iban-element{
  padding: 11px 12px;
}


.radio-button-group{
  border-radius: .25rem;
  display: inline-block;
  overflow: hidden;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
}

.radio-button{
  border-radius: 0;
  transition: none;
  width: 65px;
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
}

[data-toggle="buttons"]:not(.btn-group-colors) > .radio-button{
  background-color: unset;
}
[data-toggle="buttons"]:not(.btn-group-colors) > .radio-button:first-of-type{
  border-right: 1px solid #dee2e6;
}

.radio-button:hover{
  transform: none;
}

.invalid-stripe-input{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
