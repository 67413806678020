<template>
  <el-table
    v-if="invoices"
    :data="invoices"
    :default-sort="{prop: 'status', order: 'ascending'}"
    :row-class-name="tableRowClassName"
    class="invoices sticky-header"
    max-height="100%"
  >
    <el-table-column v-if="! compact"
      prop="id"
      label="ID"
      sortable
      min-width="53">
    </el-table-column>
    <el-table-column
      prop="description"
      label="Description"
      sortable
      min-width="74">
      <template v-slot="{row}">
        {{ row.description }}
      </template>
    </el-table-column>
    <el-table-column
      prop="restaurant_name"
      label="Restaurant"
      sortable
      min-width="80">
      <template v-slot="{row}">
        <span :class="row.restaurant_id === currentRestaurant.id ? 'font-weight-bolder' : ''">
          {{ row.restaurant_name }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      prop="total"
      label="Total"
      sortable
      min-width="47"
      :sort-method="(a, b) => (parseFloat(a['total']) < parseFloat(b['total'])) ? -1 : 1"
    >
      <template v-slot="{row}">
        {{ row.total | restaurantCurrency }}
      </template>
    </el-table-column>
    <el-table-column v-if="! compact"
      prop="status"
      label="Status"
      sortable
      :sort-method="sortStatus"
      min-width="40">
      <template v-slot="{row}">
        <badge v-if="row.status === 'paid'" type="secondary"
               style="color: green; background-color: lightgreen" class="badge-custom">Paid</badge>
        <badge v-else-if="row.status === 'draft' || row.status === 'open'" type="secondary"
               style="color: #71710F; background-color: #FFD970" class="badge-custom">{{ row.status }}</badge>
        <badge v-else-if="row.status === 'void'" type="secondary" class="badge-custom">{{ row.status }}</badge>
        <badge v-else type="secondary" style="color: #7F2626; background-color: #FF7878"
               class="badge-custom">{{ row.status }}</badge>

        <badge v-if="row.status !== 'paid' && row.status !== 'void' && row.due_at" type="secondary" class="badge-custom"
               :style="row.overdue ? 'color: #7F2626; background-color: #FF7878' : ''"
        >{{ row.overdue ? 'Overdue' : 'Due' }} {{ (row.due_at * 1000) | localDate }}</badge>
      </template>
    </el-table-column>
    <el-table-column v-if="! compact"
      prop="pdf_url"
      label="Invoice"
      min-width="55">
      <template v-slot="{row}">
        <a v-if="! row.charge_id" :href="row.pdf_url">
          Download PDF
        </a>
        <a v-else @click.prevent="downloadReceipt(row.charge_id, row.pdf_url)" href="">
          Download PDF
        </a>
        <br>
        <a v-if="row.status !== 'paid' && row.status !== 'void' && row.payment_url" :href="row.payment_url" target="_blank">
          Pay Invoice
        </a>
      </template>
    </el-table-column>
    <el-table-column v-if="compact"
                     prop="pdf_url"
                     label="Invoice"
                     min-width="38">
      <template v-slot="{row}">
        <a v-if="! row.charge_id" :href="row.pdf_url">
          View
        </a>
        <a v-else @click.prevent="downloadReceipt(row.charge_id, row.pdf_url)" href="">
          View
        </a>
      </template>
    </el-table-column>
    <el-table-column v-if="! compact"
      prop="created_at"
      label="Created at"
      sortable
      min-width="65">
      <template v-slot="{row}">
        {{new Date(row.created_at * 1000).toLocaleString()}}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import restaurantSettingsForm from "@/mixins/restaurantSettingsForm";
import {Dropdown, DropdownItem, DropdownMenu, Table, TableColumn} from "element-ui";

export default {
  mixins: [restaurantSettingsForm],
  name: "InvoicesTable",
  props: {
    restaurantId: null,
    compact: Boolean,
    onlyOpenInvoices: Boolean
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
  },
  data() {
    return {
    }
  },
  computed: {
    currentAccount() {return this.$store.getters.currentAccount},
    subscription() {return this.currentAccount.subscription},
    allInvoices() {return this.$store.getters.accountInvoices},
    invoices() {
      if (this.onlyOpenInvoices) {
        return this.openInvoices;
      }
      return this.allInvoices.filter(invoice => (! this.restaurantId) || (invoice.restaurant_id === this.restaurantId))
    },
    openInvoices() {
      return this.allInvoices.filter(invoice => invoice.status !== 'paid' && invoice.status !== 'void')
    }
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row.status === 'void') {
        return 'inactive-item';
      }
      return '';
    },
    sortStatus(a, b) {
      let sortOrder = ['uncollectible', 'open', 'void', 'draft', 'paid'];
      return sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status);
    },
    downloadReceipt(chargeId, invoicePdfUrl) {
      this.$store.dispatch('getReceiptUrl', chargeId)
        .then(function (res) {
          let receiptUrl = res.data.receipt_url;
          if (receiptUrl) {
            window.location.href = receiptUrl.split('?')[0] + '/pdf';
          } else {
            window.location.href = invoicePdfUrl;
          }
        })
        .catch(function (err) {
          window.location.href = invoicePdfUrl;
        })
    }
  }
}
</script>

<style>
.el-table.invoices .el-table__body-wrapper {
  max-height: 65vh;
}
.el-table.invoices .badge {
  white-space: break-spaces;
  line-height: 1.5;
  padding: 0.2rem 0.375rem;
}
</style>
