<template>
  <div>
    <base-dropdown menu-on-right
                   class="nav-item"
                   tag="li"
                   title-tag="a"
                   title-classes="nav-link pr-0">
      <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
        <div class="media align-items-center">
          <span v-if="notificationArray.length > 0" class="redDot">{{notificationArray.length}}</span>
          <i class="fas fa-bell" style="margin-right: 15px;"></i>
        </div>
      </a>
      <div class="on-top">
        <template>
          <div class="dropdown-header">
            <h5 class="text-overflow m-0">Notifications</h5>
          </div>
        </template>
        <div class="dropdown-divider"></div>

        <NotificationCard v-for="array in notificationArray"
                          v-bind:key="array.id"
                          v-bind:card-title="array.title"
                          v-bind:card-description="array.description"
                          v-bind:card-link="array.link"/>
        <h6 style="margin-left: 1rem;" v-if="!notificationArray.length">No notifications</h6>
      </div>
    </base-dropdown>

<!--    <modal-->
<!--      :show.sync="noticeModal"-->
<!--      modal-classes="modal-danger"-->
<!--      modal-content-classes="bg-gradient-danger"-->
<!--      noCloseOnBackdrop-->
<!--      v-if="!isAdmin && trialEnded">-->
<!--      <h6 slot="header" class="modal-title">Your attention is required</h6>-->

<!--      <div v-if="notificationArray.length && notificationArray[0].title !== 'Logo not uploaded'" class="py-3 text-center">-->
<!--        <i class="ni ni-bell-55 ni-3x"></i>-->
<!--        <h4 class="heading mt-4">{{ notificationArray[0].title }}</h4>-->
<!--        <p>{{ notificationArray[0].description }}</p>-->
<!--      </div>-->

<!--      <template slot="footer">-->
<!--        <base-button type="link" class="text-white mr-auto" @click="noticeModal = false">Close</base-button>-->
<!--        <base-button type="white" @click="noticeModal = false;$router.push(notificationArray[0].link)">Go to settings</base-button>-->
<!--      </template>-->

<!--    </modal>-->
  </div>
</template>

<script>
  import { BaseNav } from '@/components';
  import NotificationCard from '@/components/NotificationBell/NotificationCard.vue'

  export default {
    name: "NotificationsWrapper",
    components: {
      BaseNav,
      NotificationCard,
    },
    data() {
      return {
        noticeModal: false,
        showModalOnce: false,
      }
    },
    watch: {
      notificationArray: function(newVal){
        if(newVal.length > 0 && this.showModalOnce === false){
          this.showModalOnce = true;
          this.noticeModal = true;
        }
      }
    },
    computed: {
      isAdmin() {return this.$store.getters.isAdmin},
      currentRestaurant() {return this.$store.getters.currentRestaurant},
      trialEnded() {
        let trialDate = new Date(this.currentRestaurant?.trial_ends_at);
        let today = new Date();
        return trialDate < today;
      },
      addressEntered() {
        if (this.currentRestaurant && !this.currentRestaurant.address){
          return {title: 'Address missing', description: 'Please enter the address of your restaurant in settings.', link: '/settings'};
        }
        return undefined;
      },
      logoUploaded() {
        if (this.currentRestaurant && this.currentRestaurant.logo === null) {
          return {title: 'Logo not uploaded', description: 'Please upload a logo in settings.', link: '/settings#billing'};
        }
        return undefined;
      },
      mandateGiven() {
        if (this.$store.getters.mandateGiven === false) {
          return {title: 'Mandate not completed', description: 'Please configure the mandate in billing settings.', link: '/settings#billing'};
        }
        return undefined;
      },
      moreLanguagesEnabled(){
        if (this.currentRestaurant && this.currentRestaurant.languages.length === 0) {
          return {title: 'No menu language selected', description: 'Please select a language in settings.', link: '/settings'};
        }
        return undefined;
      },
      pspLinked(){
        if (this.currentRestaurant && this.currentRestaurant.psp_integration && this.currentRestaurant.psp_integration !== 'payaut' && this.currentRestaurant.psp_api_key === null) {
          return {title: 'PSP not linked', description: 'Please link the payment service provider in payment settings.', link: '/settings#payments'};
        }
        return undefined;
      },
      subscriptionSelected() {
        if (this.$store.getters.currentAccount && !this.$store.getters.currentAccount.subscription) {
          return {title: 'Subscription not selected', description: 'Please select a subscription in billing settings.', link: '/settings#billing'};
        }
        return undefined;
      },
      vatNumberEntered() {
        if (this.currentRestaurant && !this.currentRestaurant.vat_number){
          return {title: 'VAT Number missing', description: 'Please enter a VAT number in settings.', link: '/settings'};
        }
        return undefined;
      },
      notificationArray() {
        return [this.addressEntered, this.logoUploaded, this.mandateGiven, this.moreLanguagesEnabled, this.pspLinked, this.subscriptionSelected, this.vatNumberEntered].filter(Boolean);
      }
    },
  }
</script>
<style scoped>
  .on-top {
    z-index: 5000;
  }

  .nav-link {
    transition: color .2s ease;
  }

  .nav-link:hover svg {
    fill: #d64f28;
  }

  .redDot {
    position: relative;
    border: 1px solid #f8f9fe;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 45%;
    background: red;
    color: white;
    font-size: 10px;
    top: -8px;
    left: 28px;
  }
</style>
