import {diff} from "deep-object-diff";
export default {
  methods: {
    getChangedKeysFromObject(oldCard, newCard){
      console.log(oldCard);
      console.log(newCard);
      let cardDiff = diff(oldCard, newCard);
      let diffObj = {};
      Object.keys(cardDiff).forEach(function(prop){
        diffObj[prop] = newCard[prop];
      })
      diffObj.id = newCard.id;
      console.log(diffObj);
      return diffObj;
    },
  },
}
