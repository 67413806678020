<template>
  <div v-if="isAdmin">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "IsAdmin",
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  }
}
</script>

