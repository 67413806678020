import diffChecker from '@/mixins/diffChecker'
import store from "@/store";

export default {
  mixins: [diffChecker],
  name: "GeneralSettings",
  data() {
    return {
      watchMenuChange: true,
      changesMade: true,
      restaurant: undefined,
    };
  },
  computed: {
    currentRestaurant(){
      return this.$store.getters.currentRestaurant;
    },
  },
  watch: {
    restaurant: {
      handler(newVal){
        if(this.watchRestaurantChange) this.changesMade = true;
      },
      deep: true
    },
    currentRestaurant(newVal) {
      console.log('current restaurant updated');
      this.restaurant = newVal;
      this.restaurant = JSON.parse(JSON.stringify(newVal));
    }
  },
  mounted() {
    let self = this;
    this.watchRestaurantChange = false;

    function initRestaurant() {
      self.restaurant = JSON.parse(JSON.stringify(self.currentRestaurant));
      if (!self.restaurant.main_color) self.restaurant.main_color = "#d64f28"
      if (!self.restaurant.accent_color) self.restaurant.accent_color = "#c5deb8"
      self.$nextTick(function () {
        self.watchRestaurantChange = true;
      })
    }

    if(this.currentRestaurant === undefined) {
      console.log('current restaurant is undefined');
      const watcher = store.watch(() => this.currentRestaurant, serverCallCompleted => {
        watcher();
        if (serverCallCompleted) {
          initRestaurant();
        }
        else {
          alert("something went wrong, couldn't get current restaurant. error: 2350");
        }
      })
    }else{
      initRestaurant();
    }
  },
  methods: {
    resetComponent() {
      this.restaurant = JSON.parse(JSON.stringify(this.currentRestaurant));
      this.changesMade = false;
    },
    onSubmit(e) {
      console.log(e);
      //A restaurant was edited, do a PUT call for it
      let putObj = this.getChangedKeysFromObject(this.currentRestaurant, this.restaurant);
      console.log(putObj)

      if ("opening_times" in putObj) {
        console.log("opening_times in obj")
        console.log(putObj['opening_times'])
        putObj['opening_times'].forEach((item, index, object) => {
          console.log(item);
          if (item['day'] === null && item['date'] === undefined) {
            console.log("remove")
            object.splice(index, 1)
          }
        })
      }

      this.$store.dispatch('updateRestaurant', putObj)
        .then(function(){
          alert('restaurant updated successfully');
        })
        .catch(function(err) {
          alert(err)
        })

    },
  },
}
