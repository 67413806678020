<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand light">
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>

    <div>
      <i v-if="isAdmin" class="fas fa-refresh mx-2" style="font-size: 0.8rem; color: #8898aa; cursor: pointer;" @click="reloadWithoutCache">
        <tippy animation="scale" arrow arrowType="round" theme="light">Reload accounts without cache</tippy>
      </i>
    </div>
    <nav v-if="currentRestaurant" aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-1">
      <route-bread-crumb></route-bread-crumb>

    </nav>
    <div>
      <i class="fas fa-link mx-2" style="font-size: 0.8rem; color: #8898aa; cursor: pointer;" @click="copyCurrentUrl">
        <tippy animation="scale" arrow arrowType="round" theme="light">Copy Link to Current Page</tippy>
      </i>
      <Transition><small v-if="copiedToClipboard">Copied to clipboard</small></Transition>
    </div>

    <EditUserInformationModal :show="showUserInformationModal" v-on:modalClose="userInformationModal(false)"/>

    <ul class="navbar-nav align-items-center ml-auto">
      <span v-if="trialDays > 1" class="trial_days">{{trialDays}} trial days left</span>
      <span v-else-if="trialDays === 1" class="trial_days">{{trialDays}} trial day left</span>
      <span v-else-if="trialDays < 1 && trialDays > 0" class="trial_days">{{Math.round((new Date(this.currentRestaurant.trial_ends_at).getTime() - new Date().getTime()) / (3600000))}} trial hours left</span>

      <el-dropdown
        v-if="isAdmin || adminImpersonating"
        @command="setAdminImpersonateRole"
        trigger="click"
      >
        <div class="btn btn-sm btn-secondary hideAdminBtn">
          Viewing dashboard as {{ roles.find(role => role.value == adminImpersonating).label.toLowerCase() }}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu class="hideAdminDropdown" slot="dropdown">
          <el-dropdown-item
            v-for="role in roles"
            :key="role.value"
            :command="role.value"
            :disabled="role.value === adminImpersonating"
          >
           View dashboard as {{ role.label.toLowerCase() }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <NotificationsWrapper v-if="canManageAccounts || role === 'owner'"/>

      <base-dropdown menu-on-right
                     class="nav-item on-top"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
                  <span class="avatar avatar-xs mt-1 rounded-circle">
                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M75 0H0V75H11.536V65.1017C11.536 56.2323 18.5139 49.0365 27.1144 49.0365H29.0501C31.6349 50.2557 34.4863 50.949 37.5 50.949C40.5137 50.949 43.3766 50.2557 45.9499 49.0365H47.8856C56.4861 49.0365 63.4639 56.2323 63.4639 65.1017V75H75V0ZM52.3365 29.9113C52.3365 38.3622 45.6948 45.2114 37.5 45.2114C29.3051 45.2114 22.6634 38.3622 22.6634 29.9113C22.6634 21.4603 29.3051 14.6111 37.5 14.6111C45.6948 14.6111 52.3365 21.4603 52.3365 29.9113Z"/>
                    </svg>
                  </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{ nameOfUser }}</span>
            </div>
          </div>
        </a>

        <template>
          <div class="dropdown-header no-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a @click="userInformationModal(true)" href="#" class="dropdown-item">
            <i class="fa fa-user"></i>
            <span>Edit profile</span>
          </a>
          <div class="dropdown-divider"></div>
          <a @click="logout" href="#" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
  import store from "@/store";
  import {BaseNav} from '@/components';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import EditUserInformationModal from "@/views/Settings/EditUserInformationModal";
  import NotificationsWrapper from "@/components/NotificationBell/NotificationsWrapper.vue";
  import {Dropdown, DropdownItem, DropdownMenu} from 'element-ui';

  export default {
    components: {
      BaseNav,
      RouteBreadCrumb,
      EditUserInformationModal,
      NotificationsWrapper,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    computed: {
      isAdmin() {
        return this.$store.getters.isAdmin;
      },
      canManageAccounts() {
        return this.$store.getters.canManageAccounts;
      },
      role() {
        return this.$store.getters.currentRole;
      },
      routeName() {
        const {name} = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      mandateGiven() {
        return this.$store.getters.mandateGiven;
      },
      nameOfUser() {
        return this.$store.getters.userFullName;
      },
      currentRestaurant() {
        return this.$store.getters.currentRestaurant;
      },
      accountId() {
        return this.$store.getters.currentAccountId
      },
      restaurantId() {
        return this.$store.getters.currentRestaurantId
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        showUserInformationModal: false,
        trialDays: null,
        copiedToClipboard: false,
        adminImpersonating: null,
        roles: [{value: null, label: 'Admin'},{value: 'owner', label: 'Owner'},{value: 'manager', label: 'Manager'},{value: 'employee', label: 'Employee'}],
      };
    },
    mounted() {
      let self = this;
      if (this.currentRestaurant === undefined) {
        const watcher = store.watch(() => this.currentRestaurant, restaurantLoaded => {
          watcher();
          if (restaurantLoaded) {
            let currentDate = new Date()
            let trialEndDate = new Date(this.currentRestaurant.trial_ends_at)
            let timeDifference = trialEndDate.getTime() - currentDate.getTime()
            self.trialDays = Math.round(timeDifference / (1000 * 3600 * 24))
          }
        });
      } else {
        let currentDate = new Date()
        let trialEndDate = new Date(this.currentRestaurant.trial_ends_at)
        let timeDifference = trialEndDate.getTime() - currentDate.getTime()
        self.trialDays = Math.round(timeDifference / (1000 * 3600 * 24))
      }
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      userInformationModal(value) {
        this.showUserInformationModal = value;
        console.log(value);
      },
      logout() {
        let self = this;

        this.$store.dispatch('logout')
          .then(function () {
            console.log('logout successful')
          })
          .catch(err => alert(err))
      },
      reloadWithoutCache() {
        //First clear any old cache if present
        this.$store.dispatch('clearAccountCache');

        this.$store.dispatch('getAccounts', false)
          .then(function () {
            alert('cleared cache & reload successful, all account data should be up-to-date now')
          })
          .catch(err => alert(err))
      },

      copyCurrentUrl() {
        let self = this;
        let accountAndRestaurantString = `accountId=${this.accountId}&restaurantId=${this.restaurantId}`
        let query = window.location.search ? `${window.location.search}&${accountAndRestaurantString}` : `?${accountAndRestaurantString}`;


        navigator.clipboard.writeText(window.location.origin + window.location.pathname + query + window.location.hash).then(function () {
          self.copiedToClipboard = true;
          setTimeout(() => {
            self.copiedToClipboard = false
          }, 2500)
        }, function (err) {
          console.error('Could not copy text: ', err);
        });
      },
      setAdminImpersonateRole(value) {
        //Set admin impersonating value so that the dropdown stays visible for admins always
        this.adminImpersonating = value;

        this.$store.commit('setAdminImpersonateRole', value)
      }
    }
  };
</script>
<style scoped>
  .on-top {
    z-index: 1000;
  }

  .top-navbar {
    top: 0px;
  }

  .avatar {
    background: transparent;
  }

  .avatar svg {
    height: 100%;
    fill: #525f7f;
    transition: fill .2s ease;
  }

  .link {
    align-items: center;
    color: #d64f28;
  }

  .link:hover {
    color: #96371c;
  }

  .nav-link {
    transition: color .2s ease;
  }

  .nav-link:hover svg {
    fill: #d64f28;
  }

  .redDot {
    position: absolute;
    padding: 4px;
    border-radius: 50%;
    background: red;
    color: white;
    top: 1px;
    left: 27px;
  }

  .trial_days {
    padding: 10px;
    background-color: rgba(214, 79, 40, 1);
    border-radius: 5px;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
  }

  .hideAdminBtn:not(:last-child){
    margin-right: 0;
  }

  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #f5f7fa;
    color: unset;
  }



</style>

<style>
  .v-enter-active,
  .v-leave-active {
    transition: opacity .5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
