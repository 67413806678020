<template>
  <span></span>
</template>
<script>

export default {
  name: 'data-preloader',
  data() {
    return {
      preloaded: false,
      allInvoices: []
    }
  },
  computed: {
    currentAccount() {
      return this.$store.getters.currentAccount
    }
  },
  methods: {
    async doPreload() {
      if (this.preloaded) return;
      this.preloaded = true;

      while (! this.currentAccount) {
        await this.sleep(250);
      }

      this.loadInvoices();
      this.loadMenu();
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    loadInvoices() {
      this.$store.dispatch('getAccountInvoices')
    },
    loadMenu() {
      this.$store.dispatch('getMenuIfUndefined')
    }
  },
  mounted() {
    this.doPreload();
  },
};
</script>
