<template>
  <div v-if="currentRole !== 'employee' && overdueInvoices.length > 2 && paymentMethodSetupNeeded() && mandateGivenForAccount !== undefined && this.currentAccount.id !== 92 && !closedByAdmin">
    <base-alert
      type="danger" id="invoices-warning"
      class="position-fixed w-100 py-5 pr-9"
      v-resize="updateHeight">
      <span class="alert-inner--icon"><i class="fa fa-exclamation-triangle"></i></span>
      <strong class="ml-2 mr-4">Action Required</strong>
      You have {{ openInvoices.length }} unpaid {{ openInvoices.length === 1 ? 'invoice' : 'invoices' }}, please
      complete your payment.
      <button type="button" class="btn btn-secondary" @click="payInvoicesClick">
        View {{ openInvoices.length === 1 ? 'Invoice' : 'Invoices' }}
      </button>
      <span v-if="isAdmin" class="closeButton" @click="closeWarning">
        x
      </span>
    </base-alert>

    <modal id="invoices-warning-payment-setup-modal" size="xl" :show.sync="showSetupBillingModal">
      <PaymentMethodSetup ref="paymentMethodSetup" :restaurant="restaurantWithMissingPaymentMethod()"
                          :show-open-invoices-table="true" :hide-submit-button="true"/>
      <template slot="footer" v-if="$refs.paymentMethodSetup">
        <base-button type="secondary" @click="showSetupBillingModal = false">Close</base-button>
        <base-button v-if="$refs.paymentMethodSetup.isSubmitting" type="primary" disabled>
          <span class="spinner-border mr-1 text-white" role="status"></span>
          {{ $refs.restaurantPaymentMethodSetup.statusText }}
        </base-button>
        <base-button v-else type="primary" @click="$refs.paymentMethodSetup.submitForm()">Save Payment Method
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import PaymentMethodSetup from "@/views/Settings/PaymentMethodSetup";

export default {
  name: 'invoices-warning',
  components: {
    PaymentMethodSetup
  },
  data() {
    return {
      height: 0,
      showSetupBillingModal: false,
      restaurantCustomer: {},
      closedByAdmin: false,
    }
  },
  computed: {
    currentAccount() {
      return this.$store.getters.currentAccount
    },
    currentRole() {
      return this.$store.getters.currentRole
    },
    mandateGivenForAccount() {
      return this.$store.getters.mandateGiven
    },
    allInvoices() {
      return this.$store.getters.accountInvoices
    },
    accountBilling() {
      return this.$store.state.auth.onboarding.accountBilling
    },
    restaurantBilling() {
      return this.$store.state.restaurant_billing
    },
    accountCustomer() {
      return this.accountBilling.customer
    },
    overdueInvoices() {
      let self = this;
      this.$nextTick(() => {
        self.updateHeight();
      });
      return this.allInvoices.filter(invoice => invoice.overdue)
    },
    openInvoices() {
      return this.allInvoices.filter(invoice => invoice.status !== 'paid' && invoice.status !== 'void')
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    }
  },
  methods: {
    updateHeight() {
      if (document.getElementById('invoices-warning')) {
        this.height = document.getElementById('invoices-warning').offsetHeight;
      }
    },
    payInvoicesClick() {
      // $router.push('/settings?invoices=1#billing');
      this.showSetupBillingModal = true;
    },
    paymentMethodSetupNeeded() {
      return this.restaurantWithMissingPaymentMethod() !== false;
    },
    restaurantWithMissingPaymentMethod() {
      let self = this;
      this.$nextTick(() => {
        self.updateHeight();
      });

      if (!this.mandateGivenForAccount) {
        return null;
      }

      if (this.currentAccount.restaurants.length > 1) {
        let maxOverdueCount = 0;
        let maxOverdueRestaurant = false;
        this.currentAccount.restaurants.forEach((restaurant) => {
          let restaurantOverdueInvoices = this.overdueInvoices.filter(invoice => {
            return invoice.restaurant_id.toString() === restaurant.id.toString()
          })
          if (restaurantOverdueInvoices.length > maxOverdueCount) {
            if (this.restaurantCustomer[restaurant.id]) {
              let customer = this.restaurantCustomer[restaurant.id];
              if (customer.invoice_settings && customer.invoice_settings.default_payment_method) {
                return;
              }
            }
            maxOverdueRestaurant = restaurant;
            maxOverdueCount = restaurantOverdueInvoices.length;
          }
        });
        return maxOverdueRestaurant;

      } else if (this.accountCustomer) {
        if (this.accountCustomer.invoice_settings && this.accountCustomer.invoice_settings.default_payment_method) {
          return false;
        }
      }

      return null;
    },
    initBillingOfAllRestaurant() {
      let self = this;
      this.currentAccount.restaurants.forEach((restaurant) => {
        let restaurantBillingData = self.restaurantBilling[restaurant.id];
        if (restaurantBillingData && restaurantBillingData.customer) {
          self.restaurantCustomer[restaurant.id] = restaurantBillingData.customer;
        }
      });
    },
    closeWarning(){
      this.closedByAdmin = true;
      this.height = 0;
    }
  },
  mounted() {
    let self = this;
    const watcher = this.$store.watch(() => self.restaurantBilling, () => {
      watcher(); // stop watching
      //Don't apply it for Fletcher (account id 92)
      if (this.restaurantBilling && this.currentAccount.id !== 92) {
        self.initBillingOfAllRestaurant();
      }
    });
  },
};
</script>

<style scoped>
#invoices-warning {
  top: 0;
  border-color: rgb(214, 40, 40);
  background-color: rgb(214, 40, 40);
  border-radius: 0;
  font-size: 110%;
  z-index: 1001;
}

#invoices-warning button {
  position: absolute;
  right: 30px;
  top: 40px;
}

.closeButton{
  position: absolute;
  right: 12px;
}

#invoices-warning-payment-setup-modal {
  z-index: 1051;
}
</style>
